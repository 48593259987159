<template>
  <v-row>
    <v-col cols="12" class="py-0" v-if="checkRole">
      <add-document></add-document>
    </v-col>
    <v-col cols="12" class="py-0">
      <document-filter
        @input="filter = $event"
        :options="options"
      ></document-filter>
      <v-divider v-if="mobile"></v-divider>
    </v-col>
    <v-col cols="12" :class="mobile ? 'py-0' : ''">
      <document-list @input="options = $event" :filter="filter"></document-list>
    </v-col>
  </v-row>
</template>

<script>
import DocumentList from "../components/document/DocumentList";
import DocumentFilter from "../components/document/DocumentFilter";
import AddDocument from "../components/document/AddDocument";

import { mapGetters } from "vuex";

export default {
  data: function() {
    return {
      filter: {},
      options: {}
    };
  },
  components: {
    DocumentList,
    DocumentFilter,
    AddDocument
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    checkRole() {
      return this.isInRole("developer", "administrator");
    }
  }
};
</script>
